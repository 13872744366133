
.ChoosePlaylist .dialog-title-btn {
    background-image: url(../../assets/img/close-x.svg);
}

.ChoosePlaylist .dialog-title h2 {
    text-transform: none;
    font-size: 20px;
}

.ChoosePlaylist h3 {
    color: #444;
    font-size: 21px;
}

.ChoosePlaylist .dialog-content > div {
    width: 100%;
}

.ChoosePlaylist .dialog-content hr {
    display: block;
    height: 160px;
    visibility: hidden;
}

.ChoosePlaylist ul {
    margin-bottom: var(--spacing-7x);
    list-style: none;
    margin-left: 0;
    padding: 0;
}

.ChoosePlaylist ul li {
    padding: var(--spacing-2x) 0;
    font-family: var(--heading-font-family);
}

.ChoosePlaylist .how-playlists-created h3 {
    text-align: center;
    margin-bottom: var(--spacing-8x);
}

.ChoosePlaylist .how-playlists-created p {
    text-align: center;
    margin-bottom: var(--spacing-6x);
}

.ChoosePlaylist .loading .Loading {
    margin-top: var(--spacing-10x);
}

.ChoosePlaylist .loading {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media only screen and (min-width: 567px) and (min-height: 500px) {
    .ChoosePlaylist .dialog {
        top: 10vh;
        height: 80vh;
    }
    .ChoosePlaylist.show .dialog{
        top: 10vh;
        animation-duration: 0.5s;
        animation-name: choosePlaylistDialogInDesktop;
    }

    .ChoosePlaylist.hide .dialog {
        top: 100vh;
        animation-duration: 0.5s;
        animation-name: choosePlaylistDialogOutDesktop;
    }
}


@keyframes choosePlaylistDialogInDesktop {
    from {
        top: 100vh;
        opacity: 0;
    }

    to {
        top: 10vh;
        opacity: 1;
    }
}

@keyframes choosePlaylistDialogOutDesktop {
    from {
        top: 10vh;
        opacity: 1;
    }

    to {
        top: 100vh;
        opacity: 0;
    }
}
