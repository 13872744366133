.live-stream-page {
    background-color: var(--black-color);
}

.live-stream-page .Column {
    height: 100vh;
}

.live-stream-page .live-stream-content {
    width: 100%;
}

.stream-state-true {
    animation: fadeInOurAnim ease 2s;
    -webkit-animation: fadeInOurAnim ease 2s;
    -moz-animation: fadeInOurAnim ease 2s;
    -o-animation: fadeInOurAnim ease 2s;
    -ms-animation: fadeInOurAnim ease 2s;
    animation-iteration-count:infinite;
}
@keyframes fadeInOurAnim {
    0% {
        opacity:1;
    }
    50% {
        opacity:0.4;
    }
    100% {
        opacity:1;
    }
}

@-moz-keyframes fadeInOurAnim {
    0% {
        opacity:1;
    }
    50% {
        opacity:0.4;
    }
    100% {
        opacity:1;
    }
}

@-webkit-keyframes fadeInOurAnim {
    0% {
        opacity:1;
    }
    50% {
        opacity:0.4;
    }
    100% {
        opacity:1;
    }
}

@-o-keyframes fadeInOurAnim {
    0% {
        opacity:1;
    }
    50% {
        opacity:0.4;
    }
    100% {
        opacity:1;
    }
}

@-ms-keyframes fadeInOurAnim {
    0% {
        opacity: 1;
    }
    100% {
        opacity:0.4;
    }
}