.powered-by-trace,
.trace-root .powered-by-trace {
    margin: var(--spacing-16x) var(--spacing-5x);
    display: flex;
    flex-direction: column;
    align-self: center;
}

.powered-by-trace .row {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: var(--spacing-2x);
}

.powered-by-trace span {
    margin-right: 8px;
    color: var(--grey3-color);
}
