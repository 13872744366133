.team-selector-holder {
    position: relative;
    height: 1px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.team-selector-holder .team-selector {
    position: relative;
    top: -25px;
    align-self: center;
    width: 100%;
    max-width: 496px;
}

.trc-page-content.league-page-content {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
}

.map-content {
    width: 100%;
    height: 35vh;
    background-color: #f0f2f2;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white2-color);
}

.bottom-content {
    width: 100%;
    max-width: 992px;
    min-height: 65vh;
    background-color: var(--white2-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    color: black;
    padding: var(--spacing-8x) 0 0 0;
    overflow-y: auto;
    align-self: center;
}

.bottom-content h3,
.trace-root .bottom-content h3 {
    margin: var(--spacing-8x) 0 var(--spacing-5x) 0;
}

.league-main-page {
    background-color: white;
}

div:not(.trace-root) > .league-main-page .team-selector-holder {
    z-index: 100;
}

.trc-page-content.full-width {
    width: 100%;
}

@media only screen and (max-width: 414px) {
    .team-selector-holder {
        padding-left: var(--spacing-4x);
        padding-right: var(--spacing-4x);
    }
}
