


.trc-page .sticky ~ .trc-page-content {
    margin-top: 60px;
}





/*** Menu ***/

.trc-page-menu {
    width: 100%;
    height: 100vh;
    background: #fff;
    position: fixed;
    top: -200vh;
    left: 0;
    z-index: 4011;
    box-shadow: 2px 2px 5px 0 rgba(0,0,0,0.5);
    display: flex;
    flex-direction: column;
    opacity: 1;
    transition: left 0.25s, top 0.25s, opacity 0.25s;
}

.trc-page-menu.open {
    top: 0;
    opacity: 1;
}

.trc-page-menu.closed {
    top: -200vh;
    opacity: 0;
}

.trc-page-menu header {
    width: 100%;
    margin: 0 0 5px;
    padding: 25px 30px;
    background: #fff;
    box-shadow: 0 2px 0 0 #CCCCCC;
    display: flex;
    flex-direction: row;
}

.trc-page-menu .close {
    display: block;
    width: 30px;
    height: 30px;
    overflow: hidden;
    text-indent: -999999px;
    color: #37C2AC;
    background: url(../../assets/img/nav_close.svg) no-repeat center center;
    background-size: 20px 20px
}

.trc-page-menu .close:active {
    background-color: #33333322;
}

#menu-user-title {
    line-height: 1.5;
}

#menu-user-title .user-email {
    display: block;
    font-size: 12px;
    font-weight: normal;
    line-height: 1;
    text-transform: lowercase;
}

.trc-page-menu h1,
.trc-page-menu h2 {
    margin: 0;
    padding: 0;
    color: #444444;
    font-family: Montserrat;
    font-size: 21px;
    font-weight: bold;
    line-height: 30px;
    flex: 10;
}


.trc-page-menu-content {
    padding: 0;
    padding: 24px 0;
    flex: 10;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.trc-page-menu ul {
    margin: 0;
    padding: 0 0 14px;
    clear: both;
    font-size: 17px;
    list-style: none;
}

.trc-page-menu ul li {
    margin: 0;
    width: 100%;
    padding: 10px 0 10px 30px;
}

.trc-page-menu ul a {
    font-weight: 600;
    text-transform: capitalize;
}

.trc-page-menu ul a span {
    text-transform: none;
}

.trc-page-menu ul h3 {
    font-weight: 600;
    text-transform: capitalize;
}

/*** End Menu ***/





/*** Top Bar ***/

.trc-topbar {
    width: 100%;
    margin: 0;
    background: var(--accent-color);
    box-shadow: 0 1px 0 0 #CCCCCC;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    line-height: 1.5;
}

.trc-topbar.sticky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
}

.trc-topbar a {
    display: flex;
    align-items: center;
}

.trc-topbar .header-content {
    width: 100%;
    padding: 15px 20px;
    display: flex;
    justify-content: space-around;
}

.trc-topbar h1,
.trc-topbar h2,
.trc-topbar h3 {
    margin: 0;
    padding: 0;
    font-size: 21px;
    font-weight: bold;
    line-height: 30px;
}

.trc-topbar .menu {
    background: url(../../assets/img/topbar-menu-btn.svg) no-repeat center center;
    display: block;
    width: 50px;
    height: 30px;
    background-position: left;
    overflow: hidden;
    text-indent: -999999px;
    color: #37C2AC;
}

.trc-topbar .menu:active {
    background-color: #33333322;
}

.trc-topbar .logo {
    background: url(../../assets/img/topbar-logo.svg) no-repeat center center;
    overflow: hidden;
    text-indent: -999999px;
    margin: 0 10px 0;
    width: 125px;
    display: inline-block;
}

.trc-topbar h3 {
    display: none;
}

.trc-topbar .action {
    display: flex;
    justify-content: flex-end;
    width: 50px;
    height: 30px;
}

.trc-topbar .gear {
    display: flex;
    align-items: center;
}

.trc-topbar span {
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
}

.trc-topbar .gear i {
    display: inline-block;
    width: 58px;
    height: 32px;
    background-image: url(../../assets/img/edit-setting.svg);
    cursor: pointer;
    color: #37C2AC;
}

.trc-topbar .gear span {
    display: none;
}

/*** End Top Bar ***/





/* mobile only */

@media only screen and (max-width: 414px) {
    body.locked {
        height: 100vh !important; /* hack to prevent body scrolling */
        overflow: hidden !important; /* hack to prevent body scrolling */
    }

    .trc-topbar.peekaboo-xs {
        background: transparent;
        box-shadow: none;
    }

    .trc-topbar.sticky-xs {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 3;
    }

    .trc-topbar.peekaboo-xs h1,
    .trc-topbar.peekaboo-xs h3 {
        display: none;
    }

    .trc-topbar.show h3 {
        font-size: 17px;
        display: block;
    }

    .trc-page .sticky-xs ~ .trc-page-content {
        margin-top: 60px;
    }

    .trc-page .peekaboo-xs ~ .trc-page-content {
        margin-top: 0;
    }

    .trc-page-content .nav-video .profile-nav {
        padding: 0 16px;
    }
}

@media only screen and (max-width: 812px) and (max-height: 500px) {
    body.locked {
        height: 100vh !important; /* hack to prevent body scrolling */
        overflow: hidden !important; /* hack to prevent body scrolling */
    }

    .trc-topbar.peekaboo-sm {
        border-bottom: 2px solid #CCCCCC;
    }

    .trc-topbar.sticky-sm {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
    }

    .trc-page .sticky-sm ~ .trc-page-content {
        margin-top: 60px;
    }
    .trc-page .peekaboo-sm ~ .trc-page-content {
        margin-top: 0;
    }
}





/*  Tablet */
@media only screen and (min-width: 768px) and (min-height: 501px) {

    .trc-page .sticky-md ~ .trc-page-content {
        margin-top: 70px;
    }

    .trc-page-menu {
        width: 375px;
        top: 0;
        left: 0;
    }



    .trc-page-menu-content {
        overflow: hidden;
    }

    .trc-page-menu.open {
        left: 0;
        top: 0;
        opacity: 1;
    }

    .trc-page-menu.closed {
        left: -375vh;
        top: 0;
        opacity: 0;
    }

    .trc-page-menu header {
        padding: 15px 30px;
    }


    .trc-topbar.peekaboo-md {
        background: transparent;
        box-shadow: none;
    }

    .trc-topbar.scroll h3 {
        display: block;
    }

    .trc-topbar.sticky-md {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 4010;
    }

    .trc-page .sticky-md ~ .trc-page-content {
        margin-top: 60px;
    }
    .trc-page .peekaboo-md ~ .trc-page-content {
        margin-top: 0;
    }
}

/* Desktop */
@media only screen and (min-width: 1024px) {

    .trc-page-content {
        width: 960px;
        margin: 0 auto;
    }

    .trc-page .sticky-lg ~ .trc-page-content {
        margin-top: 70px;
    }

    .trc-page.team-games-page {
        display: flex;
        justify-content: center;
    }

    .trc-topbar .header-content {
        width: 960px;
        padding: 20px;
    }

    .trc-topbar.sticky-lg {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
    }

    .trc-page .sticky-lg ~ .trc-page-menu {
        z-index: 4011;
    }

    .trc-page-menu header {
        padding: 20px 30px;
    }
}





/* old */
@media only screen and (min-width: 99768px) and (min-height: 500px) {

    .trc-page {
        display: flex;
        flex-direction: row;
    }

    .trc-topbar .menu {
        display: none;
    }

    .trc-page header {
        box-shadow: none;
    }

    .trc-page header .logo {
        background: url(../../assets/img/topbar-logo.svg) no-repeat center center;
        width: 129px;
        height: 30px;
        flex: none;
        overflow: hidden;
        text-indent: -999999px;
        margin-right: 30px;
    }

    .trc-navigation {
        width: 375px;
        height: 100vh;
        position: sticky;
        top: 0;
    }

    .trc-page-content {
        flex: 10;
    }

    .trc-page-menu {
        display: block;
        position: initial;
    }

    .trc-page-menu>header {
        display: none;
    }

    .trc-page-menu ul {
        margin-top: 0;
    }

}
