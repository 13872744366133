.go-home-url {
    cursor: pointer;
}

.team-games-page .back-button {
    padding: 0 var(--spacing-4x);
    margin-bottom: var(--spacing-4x);
    margin-top: var(--spacing-11x);
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    cursor: pointer;
}

@media only screen and (max-width: 414px) {
    .team-games-page .back-button {
        margin-left: var(--spacing-4x);
        margin-right: var(--spacing-4x);
    }
    .team-games-content {
        padding-left: 0;
        padding-right: 0;
    }
}
