/* mobile portrait only */

@media only screen and (max-width: 414px) {

    .trc-topbar.sticky {
        background: transparent;
        box-shadow: none;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        transition: background 0.25s, box-shadow 0.25s;
        z-index: 3499;
    }

    .trc-topbar.show {
        background: #fff;
        box-shadow: 0 2px 0 0 #CCCCCC;
    }

    .trc-topbar .logo {
        display: none;
    }
}



/* mobile landscape */
@media only screen and (min-width: 415px) and (max-width: 812px) and (max-height: 500px) {

    .trc-topbar.sticky {
        background: transparent;
        box-shadow: none;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        transition: background 0.25s, box-shadow 0.25s;
        z-index: 3499;
    }

    .trc-topbar.show {
        background: #fff;
        box-shadow: 0 2px 0 0 #CCCCCC;
    }
}


/* tablet and Larger */

@media only screen and (min-width: 768px) and (min-height: 501px) {
    .trc-topbar.scroll .logo {
        display: none;
    }
}

/* desktop and larger*/

@media only screen and (min-width: 1024px) {

}
