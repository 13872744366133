@import url(https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:700);

/* for radar view only */


@font-face {
  font-family: 'trace-icons';
  src: url(../../static/media/trace-icons.291df435.eot);
  src: url(../../static/media/trace-icons.291df435.eot) format('embedded-opentype'),
       url(../../static/media/trace-icons.c7b1982b.woff2) format('woff2'),
       url(../../static/media/trace-icons.941fc553.woff) format('woff'),
       url(../../static/media/trace-icons.93b0b146.ttf) format('truetype'),
       url(../../static/media/trace-icons.daeede18.svg#trace-icons) format('svg');
  font-weight: normal;
  font-style: normal;
}

:root {
  --body-font-size: 17px;
  --small-font-size: 13px;
  --body-font-family: 'Montserrat', sans-serif;
}

body {
  margin      : 0;
  padding     : 0;

  background  : #fff !important;
  font-family : 'Montserrat', sans-serif !important;
  font-size   : 17px;
  font-weight : 500;
  color       : #444444;
  line-height : 22px;
}

body.scroll-locked {
  height: 100vh;
  overflow: hidden;
}


@media (orientation: landscape) {
  body.game {
    background: #000;
  }
}

/*** Typography ***/
a {
  color           : var(--accent-color);
  text-decoration : none;
}

a:active,
a:hover {
  color   : var(--accent-color-dark);
  outline : 0;
}

* {
  box-sizing : border-box;
  margin     : 0;
}

.center {
  text-align: center;
}

input,
button,
select,
textarea {
  font-family : 'Montserrat', sans-serif;
  font-family : var(--body-font-family);
  font-size   : 17px;
  font-size   : var(--body-font-size);
  font-weight : 500;
  color       : #444444;
}

form textarea {
  font-family : 'Montserrat', sans-serif;
  font-family : var(--body-font-family);
  padding: 12px 20px;
  border: #ccc 2px solid;
}

textarea:focus {
  outline: var(--trace-regular-color) 2px solid;
}

.subscript {
  font-size: 13px;
  font-size: var(--small-font-size);
}

/*** End Typography ***/


/*** Icons ***/

[class^="trc-icon-"]:before,
[class*=" trc-icon-"]:before {
  font-family: "trace-icons";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.trc-icon-feedback:before { content: '\e800'; } /* '' */
.trc-icon-spotlight-player:before { content: '\e801'; } /* '' */
.trc-icon-play:before { content: '\e802'; } /* '' */
.trc-icon-x:before { content: '\e803'; } /* '' */
.trc-icon-spotlight:before { content: '\e804'; } /* '' */


i.trace-icon {
  display         : inline-block;

  height          : 30px;
  width           : 30px;
  margin          : 5px;

  background-size : 100% 100%;
}

i.trace-icon.x2 {
  height : 60px;
  width  : 60px;
}

i.trace-icon.rotate180 {
  -webkit-transform : rotate(180deg);
          transform : rotate(180deg);
}

i.trace-icon.rotating {
  -webkit-animation : spin 1s linear infinite;
  animation         : spin 1s linear infinite;
}


h3.LoadingMessage,
p.loading {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 23px;
}
h3.LoadingMessage span {
  line-height: 34px;
  display: inline-block;
  padding-bottom: 6px;
}

/*** End Icons ***/


/* Mobile Port */
@media only screen and (max-width: 415px) {
  .hide-xs { display: none; }
  .top-hidden-xs { display: none; }
}

/* Mobile Land */
@media only screen and (min-width: 415px) and (max-width: 812px) and (max-height: 500px) {
  .hide-sm { display: none; }
  .top-hidden-sm { display: none; }
}

/* Tablet */
@media only screen and (min-width: 813px) and (max-width: 1023px) and (min-height: 501px) {
  .hide-md { display: none; }
  .top-hidden-md { display: none; }
}

/* Desktop */
@media only screen and (min-width: 1024px) {
  .hide-lg { display: none; }
  .top-hidden-lg { display: none; }
}






.trc-page .sticky ~ .trc-page-content {
    margin-top: 60px;
}





/*** Menu ***/

.trc-page-menu {
    width: 100%;
    height: 100vh;
    background: #fff;
    position: fixed;
    top: -200vh;
    left: 0;
    z-index: 4011;
    box-shadow: 2px 2px 5px 0 rgba(0,0,0,0.5);
    display: flex;
    flex-direction: column;
    opacity: 1;
    transition: left 0.25s, top 0.25s, opacity 0.25s;
}

.trc-page-menu.open {
    top: 0;
    opacity: 1;
}

.trc-page-menu.closed {
    top: -200vh;
    opacity: 0;
}

.trc-page-menu header {
    width: 100%;
    margin: 0 0 5px;
    padding: 25px 30px;
    background: #fff;
    box-shadow: 0 2px 0 0 #CCCCCC;
    display: flex;
    flex-direction: row;
}

.trc-page-menu .close {
    display: block;
    width: 30px;
    height: 30px;
    overflow: hidden;
    text-indent: -999999px;
    color: #37C2AC;
    background: url(../../static/media/nav_close.7abde6c4.svg) no-repeat center center;
    background-size: 20px 20px
}

.trc-page-menu .close:active {
    background-color: #33333322;
}

#menu-user-title {
    line-height: 1.5;
}

#menu-user-title .user-email {
    display: block;
    font-size: 12px;
    font-weight: normal;
    line-height: 1;
    text-transform: lowercase;
}

.trc-page-menu h1,
.trc-page-menu h2 {
    margin: 0;
    padding: 0;
    color: #444444;
    font-family: Montserrat;
    font-size: 21px;
    font-weight: bold;
    line-height: 30px;
    flex: 10 1;
}


.trc-page-menu-content {
    padding: 0;
    padding: 24px 0;
    flex: 10 1;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.trc-page-menu ul {
    margin: 0;
    padding: 0 0 14px;
    clear: both;
    font-size: 17px;
    list-style: none;
}

.trc-page-menu ul li {
    margin: 0;
    width: 100%;
    padding: 10px 0 10px 30px;
}

.trc-page-menu ul a {
    font-weight: 600;
    text-transform: capitalize;
}

.trc-page-menu ul a span {
    text-transform: none;
}

.trc-page-menu ul h3 {
    font-weight: 600;
    text-transform: capitalize;
}

/*** End Menu ***/





/*** Top Bar ***/

.trc-topbar {
    width: 100%;
    margin: 0;
    background: var(--accent-color);
    box-shadow: 0 1px 0 0 #CCCCCC;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    line-height: 1.5;
}

.trc-topbar.sticky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
}

.trc-topbar a {
    display: flex;
    align-items: center;
}

.trc-topbar .header-content {
    width: 100%;
    padding: 15px 20px;
    display: flex;
    justify-content: space-around;
}

.trc-topbar h1,
.trc-topbar h2,
.trc-topbar h3 {
    margin: 0;
    padding: 0;
    font-size: 21px;
    font-weight: bold;
    line-height: 30px;
}

.trc-topbar .menu {
    background: url(../../static/media/topbar-menu-btn.e8fa3d9b.svg) no-repeat center center;
    display: block;
    width: 50px;
    height: 30px;
    background-position: left;
    overflow: hidden;
    text-indent: -999999px;
    color: #37C2AC;
}

.trc-topbar .menu:active {
    background-color: #33333322;
}

.trc-topbar .logo {
    background: url(../../static/media/topbar-logo.ac12e2a0.svg) no-repeat center center;
    overflow: hidden;
    text-indent: -999999px;
    margin: 0 10px 0;
    width: 125px;
    display: inline-block;
}

.trc-topbar h3 {
    display: none;
}

.trc-topbar .action {
    display: flex;
    justify-content: flex-end;
    width: 50px;
    height: 30px;
}

.trc-topbar .gear {
    display: flex;
    align-items: center;
}

.trc-topbar span {
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
}

.trc-topbar .gear i {
    display: inline-block;
    width: 58px;
    height: 32px;
    background-image: url(../../static/media/edit-setting.72ef5f6c.svg);
    cursor: pointer;
    color: #37C2AC;
}

.trc-topbar .gear span {
    display: none;
}

/*** End Top Bar ***/





/* mobile only */

@media only screen and (max-width: 414px) {
    body.locked {
        height: 100vh !important; /* hack to prevent body scrolling */
        overflow: hidden !important; /* hack to prevent body scrolling */
    }

    .trc-topbar.peekaboo-xs {
        background: transparent;
        box-shadow: none;
    }

    .trc-topbar.sticky-xs {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 3;
    }

    .trc-topbar.peekaboo-xs h1,
    .trc-topbar.peekaboo-xs h3 {
        display: none;
    }

    .trc-topbar.show h3 {
        font-size: 17px;
        display: block;
    }

    .trc-page .sticky-xs ~ .trc-page-content {
        margin-top: 60px;
    }

    .trc-page .peekaboo-xs ~ .trc-page-content {
        margin-top: 0;
    }

    .trc-page-content .nav-video .profile-nav {
        padding: 0 16px;
    }
}

@media only screen and (max-width: 812px) and (max-height: 500px) {
    body.locked {
        height: 100vh !important; /* hack to prevent body scrolling */
        overflow: hidden !important; /* hack to prevent body scrolling */
    }

    .trc-topbar.peekaboo-sm {
        border-bottom: 2px solid #CCCCCC;
    }

    .trc-topbar.sticky-sm {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
    }

    .trc-page .sticky-sm ~ .trc-page-content {
        margin-top: 60px;
    }
    .trc-page .peekaboo-sm ~ .trc-page-content {
        margin-top: 0;
    }
}





/*  Tablet */
@media only screen and (min-width: 768px) and (min-height: 501px) {

    .trc-page .sticky-md ~ .trc-page-content {
        margin-top: 70px;
    }

    .trc-page-menu {
        width: 375px;
        top: 0;
        left: 0;
    }



    .trc-page-menu-content {
        overflow: hidden;
    }

    .trc-page-menu.open {
        left: 0;
        top: 0;
        opacity: 1;
    }

    .trc-page-menu.closed {
        left: -375vh;
        top: 0;
        opacity: 0;
    }

    .trc-page-menu header {
        padding: 15px 30px;
    }


    .trc-topbar.peekaboo-md {
        background: transparent;
        box-shadow: none;
    }

    .trc-topbar.scroll h3 {
        display: block;
    }

    .trc-topbar.sticky-md {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 4010;
    }

    .trc-page .sticky-md ~ .trc-page-content {
        margin-top: 60px;
    }
    .trc-page .peekaboo-md ~ .trc-page-content {
        margin-top: 0;
    }
}

/* Desktop */
@media only screen and (min-width: 1024px) {

    .trc-page-content {
        width: 960px;
        margin: 0 auto;
    }

    .trc-page .sticky-lg ~ .trc-page-content {
        margin-top: 70px;
    }

    .trc-page.team-games-page {
        display: flex;
        justify-content: center;
    }

    .trc-topbar .header-content {
        width: 960px;
        padding: 20px;
    }

    .trc-topbar.sticky-lg {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
    }

    .trc-page .sticky-lg ~ .trc-page-menu {
        z-index: 4011;
    }

    .trc-page-menu header {
        padding: 20px 30px;
    }
}





/* old */
@media only screen and (min-width: 99768px) and (min-height: 500px) {

    .trc-page {
        display: flex;
        flex-direction: row;
    }

    .trc-topbar .menu {
        display: none;
    }

    .trc-page header {
        box-shadow: none;
    }

    .trc-page header .logo {
        background: url(../../static/media/topbar-logo.ac12e2a0.svg) no-repeat center center;
        width: 129px;
        height: 30px;
        flex: none;
        overflow: hidden;
        text-indent: -999999px;
        margin-right: 30px;
    }

    .trc-navigation {
        width: 375px;
        height: 100vh;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
    }

    .trc-page-content {
        flex: 10 1;
    }

    .trc-page-menu {
        display: block;
        position: initial;
    }

    .trc-page-menu>header {
        display: none;
    }

    .trc-page-menu ul {
        margin-top: 0;
    }

}

/* mobile portrait only */

@media only screen and (max-width: 414px) {

    .trc-topbar.sticky {
        background: transparent;
        box-shadow: none;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        transition: background 0.25s, box-shadow 0.25s;
        z-index: 3499;
    }

    .trc-topbar.show {
        background: #fff;
        box-shadow: 0 2px 0 0 #CCCCCC;
    }

    .trc-topbar .logo {
        display: none;
    }
}



/* mobile landscape */
@media only screen and (min-width: 415px) and (max-width: 812px) and (max-height: 500px) {

    .trc-topbar.sticky {
        background: transparent;
        box-shadow: none;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        transition: background 0.25s, box-shadow 0.25s;
        z-index: 3499;
    }

    .trc-topbar.show {
        background: #fff;
        box-shadow: 0 2px 0 0 #CCCCCC;
    }
}


/* tablet and Larger */

@media only screen and (min-width: 768px) and (min-height: 501px) {
    .trc-topbar.scroll .logo {
        display: none;
    }
}

/* desktop and larger*/

@media only screen and (min-width: 1024px) {

}

.team-games-page {
    background-color: var(--white2-color);
}

.team-games-content {
    display: flex;
    flex-direction: column;
    padding-left: var(--spacing-8x);
    padding-right: var(--spacing-8x);
}

.team-games-page .pseudo-selector {
    padding: 0 var(--spacing-4x);
    border: 2px solid var(--component-border-color);
    margin-bottom: var(--spacing-4x);
    margin-top: var(--spacing-11x);
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    cursor: pointer;
}

@media only screen and (max-width: 414px) {
    .team-games-page .pseudo-selector {
        margin-left: var(--spacing-4x);
        margin-right: var(--spacing-4x);
    }
    .team-games-content {
        padding-left: 0;
        padding-right: 0;
    }
}
.powered-by-trace,
.trace-root .powered-by-trace {
    margin: var(--spacing-16x) var(--spacing-5x);
    display: flex;
    flex-direction: column;
    align-self: center;
}

.powered-by-trace .row {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: var(--spacing-2x);
}

.powered-by-trace span {
    margin-right: 8px;
    color: var(--grey3-color);
}

.go-home-url {
    cursor: pointer;
}

.team-games-page .back-button {
    padding: 0 var(--spacing-4x);
    margin-bottom: var(--spacing-4x);
    margin-top: var(--spacing-11x);
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    cursor: pointer;
}

@media only screen and (max-width: 414px) {
    .team-games-page .back-button {
        margin-left: var(--spacing-4x);
        margin-right: var(--spacing-4x);
    }
    .team-games-content {
        padding-left: 0;
        padding-right: 0;
    }
}


.ChoosePlaylist .dialog-title-btn {
    background-image: url(../../static/media/close-x.4f654977.svg);
}

.ChoosePlaylist .dialog-title h2 {
    text-transform: none;
    font-size: 20px;
}

.ChoosePlaylist h3 {
    color: #444;
    font-size: 21px;
}

.ChoosePlaylist .dialog-content > div {
    width: 100%;
}

.ChoosePlaylist .dialog-content hr {
    display: block;
    height: 160px;
    visibility: hidden;
}

.ChoosePlaylist ul {
    margin-bottom: var(--spacing-7x);
    list-style: none;
    margin-left: 0;
    padding: 0;
}

.ChoosePlaylist ul li {
    padding: var(--spacing-2x) 0;
    font-family: var(--heading-font-family);
}

.ChoosePlaylist .how-playlists-created h3 {
    text-align: center;
    margin-bottom: var(--spacing-8x);
}

.ChoosePlaylist .how-playlists-created p {
    text-align: center;
    margin-bottom: var(--spacing-6x);
}

.ChoosePlaylist .loading .Loading {
    margin-top: var(--spacing-10x);
}

.ChoosePlaylist .loading {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media only screen and (min-width: 567px) and (min-height: 500px) {
    .ChoosePlaylist .dialog {
        top: 10vh;
        height: 80vh;
    }
    .ChoosePlaylist.show .dialog{
        top: 10vh;
        -webkit-animation-duration: 0.5s;
                animation-duration: 0.5s;
        -webkit-animation-name: choosePlaylistDialogInDesktop;
                animation-name: choosePlaylistDialogInDesktop;
    }

    .ChoosePlaylist.hide .dialog {
        top: 100vh;
        -webkit-animation-duration: 0.5s;
                animation-duration: 0.5s;
        -webkit-animation-name: choosePlaylistDialogOutDesktop;
                animation-name: choosePlaylistDialogOutDesktop;
    }
}


@-webkit-keyframes choosePlaylistDialogInDesktop {
    from {
        top: 100vh;
        opacity: 0;
    }

    to {
        top: 10vh;
        opacity: 1;
    }
}


@keyframes choosePlaylistDialogInDesktop {
    from {
        top: 100vh;
        opacity: 0;
    }

    to {
        top: 10vh;
        opacity: 1;
    }
}

@-webkit-keyframes choosePlaylistDialogOutDesktop {
    from {
        top: 10vh;
        opacity: 1;
    }

    to {
        top: 100vh;
        opacity: 0;
    }
}

@keyframes choosePlaylistDialogOutDesktop {
    from {
        top: 10vh;
        opacity: 1;
    }

    to {
        top: 100vh;
        opacity: 0;
    }
}

.team-selector-holder {
    position: relative;
    height: 1px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.team-selector-holder .team-selector {
    position: relative;
    top: -25px;
    align-self: center;
    width: 100%;
    max-width: 496px;
}

.trc-page-content.league-page-content {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
}

.map-content {
    width: 100%;
    height: 35vh;
    background-color: #f0f2f2;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white2-color);
}

.bottom-content {
    width: 100%;
    max-width: 992px;
    min-height: 65vh;
    background-color: var(--white2-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    color: black;
    padding: var(--spacing-8x) 0 0 0;
    overflow-y: auto;
    align-self: center;
}

.bottom-content h3,
.trace-root .bottom-content h3 {
    margin: var(--spacing-8x) 0 var(--spacing-5x) 0;
}

.league-main-page {
    background-color: white;
}

div:not(.trace-root) > .league-main-page .team-selector-holder {
    z-index: 100;
}

.trc-page-content.full-width {
    width: 100%;
}

@media only screen and (max-width: 414px) {
    .team-selector-holder {
        padding-left: var(--spacing-4x);
        padding-right: var(--spacing-4x);
    }
}

.live-stream-page {
    background-color: var(--black-color);
}

.live-stream-page .Column {
    height: 100vh;
}

.live-stream-page .live-stream-content {
    width: 100%;
}

.stream-state-true {
    animation: fadeInOurAnim ease 2s;
    -webkit-animation: fadeInOurAnim ease 2s;
    -moz-animation: fadeInOurAnim ease 2s;
    -o-animation: fadeInOurAnim ease 2s;
    -ms-animation: fadeInOurAnim ease 2s;
    -webkit-animation-iteration-count:infinite;
            animation-iteration-count:infinite;
}
@keyframes fadeInOurAnim {
    0% {
        opacity:1;
    }
    50% {
        opacity:0.4;
    }
    100% {
        opacity:1;
    }
}

@-webkit-keyframes fadeInOurAnim {
    0% {
        opacity:1;
    }
    50% {
        opacity:0.4;
    }
    100% {
        opacity:1;
    }
}

.tags-page {
    background-color: var(--white2-color);
}

.tags-page .tags-selector-holder {
    max-width: 800px;
    flex-grow: 1;
}
