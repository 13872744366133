.team-games-page {
    background-color: var(--white2-color);
}

.team-games-content {
    display: flex;
    flex-direction: column;
    padding-left: var(--spacing-8x);
    padding-right: var(--spacing-8x);
}

.team-games-page .pseudo-selector {
    padding: 0 var(--spacing-4x);
    border: 2px solid var(--component-border-color);
    margin-bottom: var(--spacing-4x);
    margin-top: var(--spacing-11x);
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    cursor: pointer;
}

@media only screen and (max-width: 414px) {
    .team-games-page .pseudo-selector {
        margin-left: var(--spacing-4x);
        margin-right: var(--spacing-4x);
    }
    .team-games-content {
        padding-left: 0;
        padding-right: 0;
    }
}