
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700');

/* for radar view only */
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:700');


@font-face {
  font-family: 'trace-icons';
  src: url('./assets/fonts/trace-icons.eot');
  src: url('./assets/fonts/trace-icons.eot') format('embedded-opentype'),
       url('./assets/fonts/trace-icons.woff2?40262788') format('woff2'),
       url('./assets/fonts/trace-icons.woff?40262788') format('woff'),
       url('./assets/fonts/trace-icons.ttf?40262788') format('truetype'),
       url('./assets/fonts/trace-icons.svg?40262788#trace-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

:root {
  --body-font-size: 17px;
  --small-font-size: 13px;
  --body-font-family: 'Montserrat', sans-serif;
}

body {
  margin      : 0;
  padding     : 0;

  background  : #fff !important;
  font-family : 'Montserrat', sans-serif !important;
  font-size   : 17px;
  font-weight : 500;
  color       : #444444;
  line-height : 22px;
}

body.scroll-locked {
  height: 100vh;
  overflow: hidden;
}


@media (orientation: landscape) {
  body.game {
    background: #000;
  }
}

/*** Typography ***/
a {
  color           : var(--accent-color);
  text-decoration : none;
}

a:active,
a:hover {
  color   : var(--accent-color-dark);
  outline : 0;
}

* {
  box-sizing : border-box;
  margin     : 0;
}

.center {
  text-align: center;
}

input,
button,
select,
textarea {
  font-family : var(--body-font-family);
  font-size   : var(--body-font-size);
  font-weight : 500;
  color       : #444444;
}

form textarea {
  font-family : var(--body-font-family);
  padding: 12px 20px;
  border: #ccc 2px solid;
}

textarea:focus {
  outline: var(--trace-regular-color) 2px solid;
}

.subscript {
  font-size: var(--small-font-size);
}

/*** End Typography ***/


/*** Icons ***/

[class^="trc-icon-"]:before,
[class*=" trc-icon-"]:before {
  font-family: "trace-icons";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.trc-icon-feedback:before { content: '\e800'; } /* '' */
.trc-icon-spotlight-player:before { content: '\e801'; } /* '' */
.trc-icon-play:before { content: '\e802'; } /* '' */
.trc-icon-x:before { content: '\e803'; } /* '' */
.trc-icon-spotlight:before { content: '\e804'; } /* '' */


i.trace-icon {
  display         : inline-block;

  height          : 30px;
  width           : 30px;
  margin          : 5px;

  background-size : 100% 100%;
}

i.trace-icon.x2 {
  height : 60px;
  width  : 60px;
}

i.trace-icon.rotate180 {
  transform : rotate(180deg);
}

i.trace-icon.rotating {
  -webkit-animation : spin 1s linear infinite;
  -moz-animation    : spin 1s linear infinite;
  animation         : spin 1s linear infinite;
}


h3.LoadingMessage,
p.loading {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 23px;
}
h3.LoadingMessage span {
  line-height: 34px;
  display: inline-block;
  padding-bottom: 6px;
}

/*** End Icons ***/


/* Mobile Port */
@media only screen and (max-width: 415px) {
  .hide-xs { display: none; }
  .top-hidden-xs { display: none; }
}

/* Mobile Land */
@media only screen and (min-width: 415px) and (max-width: 812px) and (max-height: 500px) {
  .hide-sm { display: none; }
  .top-hidden-sm { display: none; }
}

/* Tablet */
@media only screen and (min-width: 813px) and (max-width: 1023px) and (min-height: 501px) {
  .hide-md { display: none; }
  .top-hidden-md { display: none; }
}

/* Desktop */
@media only screen and (min-width: 1024px) {
  .hide-lg { display: none; }
  .top-hidden-lg { display: none; }
}


